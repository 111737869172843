import React, { Component } from "react";
import { func } from "prop-types";
import { navigate } from "gatsby";
import { connect } from "react-redux";
import { Button } from "@instructure/ui-buttons";
import IconArrowOpenEnd from "@instructure/ui-icons/lib/IconArrowOpenEndSolid";
import IconArrowOpenStart from "@instructure/ui-icons/lib/IconArrowOpenStartSolid";
import { RadioInputGroup, RadioInput } from "@instructure/ui-forms";
import { ScreenReaderContent } from "@instructure/ui-a11y";
import ReactMedia from "react-media";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import QuestionHeader from "../../../components/question-header";
import styles from "./high-risk-activity.module.css";
import { setCurrentModule, setCurrentStep } from "../../../state/actions";

const ACTIVITIES = [
  "Mountain biking with the Scouts",
  "Going with friends to a movie",
  "A day of snowboarding with your family",
  "Indoor rock climbing at a gym"
];

const HIGH_RISK = [
  "A day of snowboarding with your family",
  "Mountain biking with the Scouts"
];

const NOT_HIGH_RISK = [
  "Indoor rock climbing at a gym",
  "Going with friends to a movie"
];

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "rgba(99, 175, 0, 1)" : "#472453",
  color: isDragging ? "#000" : "#fff",
  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "rgba(234, 60, 150, 0.25)" : "white",
  padding: grid,
  // width: 250,
  border: "2px black solid"
});
export class HighRiskActivity extends Component {
  static propTypes = {
    handleNavigate: func,
    goToStep: func
  };

  state = {
    hasSubmitted: false,
    activity: ACTIVITIES.map(() => null)
  };

  componentDidMount() {
    // Make sure that the progress bar always shows
    this.props.handleNavigate(1);
    this.props.goToStep(6);
  }

  onSubmit = () => {
    if (!this.state.hasSubmitted) {
      this.setState({ hasSubmitted: true });
    } else {
      this.props.goToStep(6, "/modules/normalcy/ensure-safety");
    }
  };

  goBack = () => {
    if (this.state.hasSubmitted) {
      this.setState({ hasSubmitted: false });
    } else {
      this.props.goToStep(6, "/modules/normalcy/high-risk");
    }
  };

  updateActivityState = (value, index) => {
    const newActivity = this.state.activity.slice();
    newActivity[index] = value;
    this.setState({
      activity: newActivity
    });
  };

  onDragEnd = result => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      // ???
    } else {
      switch (destination.droppableId) {
        case "unspecifiedArea":
          this.updateActivityState(null, result.draggableId);
          break;
        case "highRiskArea":
          this.updateActivityState("highrisk", result.draggableId);
          break;
        case "notHighRiskArea":
          this.updateActivityState("nothighrisk", result.draggableId);
          break;
      }
    }
  };

  renderSmall() {
    return (
      <>
        <div className={styles.instructions}>
          <QuestionHeader
            icon={
              <span
                className={styles.emoji}
                role="img"
                aria-label="Thinking Face"
              >
                🤔
              </span>
            }
          >
            <span>
              Select if the following activities are high risk or not.
            </span>
          </QuestionHeader>
        </div>
        <div className={styles.smallContainer}>
          {ACTIVITIES.map((activity, index) => (
            <div key={activity} className={styles.activityContainer}>
              <div className={styles.activityName}>{activity}</div>
              <div className={styles.activityToggle}>
                <RadioInputGroup
                  name={`activity${index}`}
                  value={this.state.activity[index]}
                  onChange={e =>
                    this.updateActivityState(e.currentTarget.value, index)
                  }
                  description={
                    <ScreenReaderContent>{`Is ${activity} risky?`}</ScreenReaderContent>
                  }
                  variant="toggle"
                  size="small"
                >
                  <RadioInput
                    label="High Risk"
                    value="highrisk"
                    context="off"
                  />
                  <RadioInput
                    label="Not High Risk"
                    value="nothighrisk"
                    context="off"
                  />
                </RadioInputGroup>
                {this.state.hasSubmitted ? (
                  this.state.activity[index] === "highrisk" ? (
                    HIGH_RISK.includes(activity) ? (
                      <div className={styles.smallFeedbackPositive}>
                        Correct
                      </div>
                    ) : (
                      <div className={styles.smallFeedbackNegative}>
                        Incorrect
                      </div>
                    )
                  ) : NOT_HIGH_RISK.includes(activity) ? (
                    <div className={styles.smallFeedbackPositive}>Correct</div>
                  ) : (
                    <div className={styles.smallFeedbackNegative}>
                      Incorrect
                    </div>
                  )
                ) : null}
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }

  renderLarge() {
    const unspecifiedItems = ACTIVITIES.map((item, index) => {
      if (this.state.activity[index] == null) {
        return { id: index.toString(), content: item };
      }
      return null;
    }).filter(x => !!x);

    const highRiskItems = this.state.activity
      .map((value, index) => {
        if (value === "highrisk") {
          return { id: index.toString(), content: ACTIVITIES[index] };
        }
        return null;
      })
      .filter(x => !!x);

    const notHighRiskItems = this.state.activity
      .map((value, index) => {
        if (value === "nothighrisk") {
          return { id: index.toString(), content: ACTIVITIES[index] };
        }
        return null;
      })
      .filter(x => !!x);

    return (
      <>
        <div className={styles.instructions}>
          <QuestionHeader
            icon={
              <span
                className={styles.emoji}
                role="img"
                aria-label="Thinking Face"
              >
                🤔
              </span>
            }
          >
            <span>Drag each activity to the proper category.</span>
          </QuestionHeader>
        </div>
        <div className={styles.largeContainer}>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable
              className={styles.dropArea}
              droppableId="unspecifiedArea"
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  <h3>Activities</h3>
                  {unspecifiedItems.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          {item.content}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <Droppable className={styles.dropArea} droppableId="highRiskArea">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  <h3>High Risk</h3>
                  {highRiskItems.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          {item.content}
                          {this.state.hasSubmitted ? (
                            HIGH_RISK.includes(item.content) ? (
                              <div className={styles.largeFeedbackPositive}>
                                Correct
                              </div>
                            ) : (
                              <div className={styles.largeFeedbackNegative}>
                                Incorrect
                              </div>
                            )
                          ) : null}
                        </div>
                      )}
                    </Draggable>
                  ))}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <Droppable
              className={styles.dropArea}
              droppableId="notHighRiskArea"
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  <h3>Not High Risk</h3>
                  {notHighRiskItems.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          {item.content}
                          {this.state.hasSubmitted ? (
                            NOT_HIGH_RISK.includes(item.content) ? (
                              <div className={styles.largeFeedbackPositive}>
                                Correct
                              </div>
                            ) : (
                              <div className={styles.largeFeedbackNegative}>
                                Incorrect
                              </div>
                            )
                          ) : null}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </>
    );
  }

  render() {
    return (
      <Layout isModule>
        <SEO
          title="Module - Normalcy - High Risk Activities"
          keywords={["utah foster care", "foster care"]}
        />
        <div className={styles.moduleGridContainer}>
          <ReactMedia query={{ minWidth: "768px" }}>
            {matches => (matches ? this.renderLarge() : this.renderSmall())}
          </ReactMedia>
          <div className={styles.backButtonArea}>
            <Button
              onClick={this.goBack}
              variant="success"
              icon={IconArrowOpenStart}
            />
          </div>
          <div className={styles.forwardButtonArea}>
            <Button
              onClick={this.onSubmit}
              variant="success"
              icon={IconArrowOpenEnd}
              disabled={this.state.activity.some(x => !x)}
            />
          </div>
        </div>
      </Layout>
    );
  }
}

const stateToProps = (state, ownProps) => ({
  ...ownProps
});

const dispatchToProps = dispatch => {
  return {
    handleNavigate(moduleId) {
      dispatch(setCurrentModule(moduleId));
    },
    goToStep(stepId, navigateTo) {
      dispatch(setCurrentStep(stepId));
      if (navigateTo) {
        navigate(navigateTo);
      }
    }
  };
};

export default connect(
  stateToProps,
  dispatchToProps
)(HighRiskActivity);
